import { videosEndpoint } from '../services/endpoints';

export const updateMovement = async (idMovement, formValues) => {
  console.log('formValues',formValues);
  try {
    let serviceResponse;
    const response = await fetch(`${videosEndpoint}/${idMovement}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formValues),
    });
    const parsedResponse = await response.json();
console.log('parsedResponse',parsedResponse);
    if (response.status === 204) {
      serviceResponse = { success: true, data: parsedResponse };
    } else {
      serviceResponse = { success: false, data: parsedResponse };
    }

    return serviceResponse;
  } catch (error) {
    console.error('Error updating movement: ', error);
    return {
      success: false,
      data: error,
    };
  }
};

export default updateMovement;
